import { CompanyProfileSettings } from './components/CompanyProfileSettings'
import { SmsSettings } from './components/SmsSettings'
import { Notifications } from './components/Notifications'

export function CompanySettings() {
    return (
        <div className="settings_container">
            <CompanyProfileSettings />
            <SmsSettings />
            <Notifications />
        </div>
    )
}
