import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { Avatar } from '../../../components/Avatar';
import { UserModel } from '../../../models'
import { RootState } from '../../../../setup'
import { useSelector, shallowEqual } from 'react-redux'

export function AccountHeader() {
  const location = useLocation()
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  return (
    <div className='card mb-5 mb-xl-10 account_header_wrapper'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <Avatar
                url={user.avatarUrl}
                alt={`Avatar of ${user.firstName} ${user.lastName}`} />
              <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-3'>
                    {user.firstName} {user.lastName}
                  </a>
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <img className='me-1' src="/media/icons/duotune/communication/com006.svg" width="18" alt="" />
                    Superadmin
                  </a>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                  >
                    <img className='me-1' src="/media/icons/duotune/communication/com011.svg" width="18" alt="" />
                    {user.email}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='overflow-auto mb-5'>
          <ul style={{'justifyContent': 'safe center'}}
              className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap text-nowrap mb-5'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/superadmin-account/overview' && 'active')
                }
                to='/superadmin-account/overview'
              >
                Overview
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/superadmin-account/user-settings' && 'active')
                }
                to='/superadmin-account/user-settings'
              >
                User
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/superadmin-account/company-settings' && 'active')
                }
                to='/superadmin-account/company-settings'
              >
                Company
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === `/superadmin-account/disciplines` && 'active')
                }
                to={`/superadmin-account/disciplines`}
              >
                Corrective Actions
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === `/superadmin-account/attendances` && 'active')
                }
                to={`/superadmin-account/attendances`}
              >
                Attendances
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/superadmin-account/coaching' && 'active')
                }
                to='/superadmin-account/coaching'
              >
                Coaching
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/superadmin-account/recognition' && 'active')
                }
                to='/superadmin-account/recognition'
              >
                Recognition
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/superadmin-account/invite' && 'active')
                }
                to='/superadmin-account/invite'
              >
                Invite
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={clsx('nav-link text-active-primary me-6', {
                  active: location.pathname === '/superadmin-account/imports',
                })}
                to="/superadmin-account/imports"
              >
                Imports
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
