import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Employee } from '../../../../app/modules/employee/models/Employee.model';
import { SkeDialogDelete } from '../../../../common/components/dialog-delete';
import { ModalDisplayMode } from '../../../../common/interfaces/modal.model';
import { disciplineModalSlice, DisciplineModalState, DisciplineModalStateUnion } from '../../discipline-modal.slice';
import { disciplineSlice } from '../../discipline.api';
import { DisciplineModal } from '../../modals/DisciplineModal';

interface Props {
	state: DisciplineModalStateUnion & Pick<DisciplineModalState, 'showModal' | 'recordId'>;
}

export function DisciplineLayout({state}: Props) {
	const dispatch = useDispatch();
	const [deleteDiscipline] = disciplineSlice.useDeleteDisciplineMutation();
	const disciplineViewModalRef = useRef<HTMLButtonElement>(null);
	const previousState = useRef<DisciplineModalStateUnion>();
	const [getOneDisciplineFilter, setGetOneDisciplineFilter] = useState<{id: number}>();
	const {
		data: discipline,
		refetch,
		isUninitialized,
		fulfilledTimeStamp,
	} = disciplineSlice.useGetOneDisciplineQuery(getOneDisciplineFilter || skipToken);


	useEffect(() => {
		if (!state.record && state.recordId) {
			const force = !state.record && !!previousState.current?.record;
			handleLoadRecord(state.recordId, force);
			return;
		}
		if (state.record && state.record?.id === state.recordId && state.showModal) {
			switch (state.mode) {
				case ModalDisplayMode.Read:
					disciplineViewModalRef.current?.click();
					break;
			}
			previousState.current = state;
			return;
		}
	}, [state]);

	useEffect(() => {
		if (!!discipline && discipline.id === state.recordId) {
			dispatch(disciplineModalSlice.actions.show({
				...state,
				record: discipline,
			}));
		}
	}, [discipline, fulfilledTimeStamp]);

	const handleLoadRecord = (id: number, force: boolean) => {
		setGetOneDisciplineFilter({id});
		if (force) {
			if (isUninitialized) {
				return;
			}
			refetch();
		}
	};

	const handleCancelDelete = () => {
		dispatch(disciplineModalSlice.actions.hide(false));
	};

	const handleConfirmDelete = (errorToast: Function, successToast: Function) => {
		if (state.mode !== ModalDisplayMode.Delete || !state.recordId) {
			throw new Error('Unable to delete record as no ID was passed in');
		}
		deleteDiscipline({id: state.recordId})
			.unwrap()
			.then(() => {
				successToast();
				dispatch(disciplineModalSlice.actions.hide(true));
			})
			.catch(() => {
				errorToast();
				dispatch(disciplineModalSlice.actions.hide(false));
			});
	};

	return (
		<>
			<button
				className="d-none"
				ref={disciplineViewModalRef}
			>
			</button>
			{state.mode === ModalDisplayMode.Create && state.showModal && (
				<>
					<DisciplineModal
						employee={state.employee}
						show={state.showModal}
						onSubmit={() => {
							dispatch(disciplineModalSlice.actions.hide(true));
							refetch();
						}}
						onClose={() => dispatch(disciplineModalSlice.actions.hide(false))}
					/>
				</>
			)}
			{state.record && state.mode === ModalDisplayMode.Read &&
				<DisciplineModal
					employee={(state.record.employee as unknown as Employee)}
					disciplineId={state.record.id}
					show={state.showModal}
					// refresh={refetch}
					onSubmit={() => {
						dispatch(disciplineModalSlice.actions.hide(true));
						refetch();
					}}
					onClose={() => dispatch(disciplineModalSlice.actions.hide(false))}
				/>
			}
			{state.mode === ModalDisplayMode.Delete &&
				<SkeDialogDelete
					onCancel={handleCancelDelete}
					onConfirm={handleConfirmDelete}
					successMessage="Discipline deleted"
					message="Are you sure you want to delete this?"
				/>
			}
		</>
	);
}
