import { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toAbsoluteUrl } from '../../../_library/helpers';
import { ModalDisplayMode } from '../../../common/interfaces/modal.model';
import { DisciplineModalReadState, disciplineModalSlice } from '../../../features/discipline/discipline-modal.slice';
import {
	Discipline,
	DisciplineStatusForHumansEnum,
	DisciplineStatusType,
} from '../../../features/discipline/interfaces/discipline.model'
import x_icon from '../../../images/connections/x.svg';
import { RootState } from '../../../setup';
import { getOneDiscipline } from '../../CRUD/DisciplineCRUD';
import { deleteOneNotification } from '../../CRUD/TeamCRUD';
import { ConnectionTypeEnum } from '../../types/ConnectionType';
import { DisciplineType } from '../../types/DisciplineType';
import { NotificationType } from '../../types/NotificationType';
import './NotificationViewModal.scss';

interface Props {
    notification: NotificationType | null
    refreshNotifications: () => void
}

export function NotificationViewModal({
																				notification,
																				refreshNotifications,
																			}: Props) {
	const closeModalRef = useRef<HTMLButtonElement>(null);
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string
	const [discipline, setDiscipline] = useState<DisciplineType | null>(null);
	const [isOutOfDate, setIsOutOfDate] = useState<boolean>(false);
	const [isRecordDeleted, setIsRecordDeleted] = useState<boolean>(false);
	const [isErrored, setIsErrored] = useState<boolean>(false);
	const HTTP_STATUS_NOT_FOUND = 404;
	const [showDisciplineModal, setShowDisciplineModal] = useState<boolean>(false);

	const dispatch = useDispatch();

	const handleDismissNotification = () => {
		if (!!notification) {
			deleteOneNotification(notification.id, token)
				.then(() => refreshNotifications())
				.catch(err => console.log(err));
			closeModalRef.current?.click();
		}
	};

	useEffect(() => {
		let abortController = new AbortController();
		const metaData: any = notification?.metaData;
		if (metaData?.id) {
			getOneDiscipline(metaData?.id, token)
				.then(({ data }) => {
					setDiscipline(data)
				}).catch(err => {
					if (err.response.status === HTTP_STATUS_NOT_FOUND) {
						setIsRecordDeleted(true);
						setIsOutOfDate(false);
					} else {
						setIsErrored(true);
						setIsRecordDeleted(false);
						setIsOutOfDate(false);
					}
			})
		}

		return () => {
			abortController.abort();
		}
	}, [notification, token])

	useEffect(() => {
		const isOutdated = (notification?.metaData as any)?.status as DisciplineStatusType !== discipline?.status;
		setIsOutOfDate(isOutdated);
	}, [discipline]);

	const handleShowDisciplineModal = ()=> {
		const payload: DisciplineModalReadState = {
			mode: ModalDisplayMode.Read,
			record: (discipline as unknown as Discipline),
		}
		dispatch(disciplineModalSlice.actions.show(payload));
	}

	return (
		<>
			<div className="modal fade"
					 id="notification_view_modal"
					 aria-hidden="true">
				<div className="modal-dialog">
					<div className="modal-content w-lg-700px">
						<div className="modal-body mx-0 p-0">
							<div className="modal_body_header d-flex align-items-center justify-content-between">
								<div className="modal_body_header_left d-flex align-items-center">
									<img
										className="rounded-circle"
										src={((notification?.metaData as any)?.employee?.avatarUrl == null || (notification?.metaData as any).employee?.avatarUrl.includes('media/icons/duotune/general/create-team-icon')) ? toAbsoluteUrl('/media/avatars/blank.png') : (notification?.metaData as any)?.employee?.avatarUrl}
										alt="avatar"
										width="32"
										height="32"
									/>
									<div className="header_content">
										<div className="employee_name">
											{(notification?.metaData as any)?.employee?.firstName} {(notification?.metaData as any)?.employee?.lastName}
										</div>
										<div className="team_name">
											{(notification?.metaData as any)?.team?.name}
										</div>
									</div>
								</div>
								<h1 className="align-self-center">Notification</h1>
								<button className="btn close_button"
												data-bs-dismiss="modal"
								>
									<img width="24"
											 src={x_icon}
											 alt="close"
									/>
								</button>
							</div>
							<div className="modal_body_content">
								<div className="details_wrapper">
									<div className="details_body">
										{isOutOfDate &&
											<>
												<div className="row">
													<h3 className="text-danger text-center">This notification is out of date</h3>
												</div>
												<div className="row">
													<p className="fw-light text-danger text-center">Current status: {DisciplineStatusForHumansEnum[(discipline?.status as DisciplineStatusType)]}</p>
												</div>
											</>
										}
										{isRecordDeleted && (
											<div className="row">
												<h3 className="text-danger text-center">The record linked to this notification has been deleted.</h3>
											</div>
										)}
										{isErrored && (
											<div className="row">
												<h3 className="text-danger text-center">Encountered unknown error retrieving current status.</h3>
											</div>
										)}
										<div className="row mb-5">
											<div className="row pb-5">
												<div className="col-md-2">
													<div className="item_title">DATE</div>
													<div className="item_details">
														{(notification?.metaData as any)?.date}
													</div>
												</div>
												<div className="col-md-4">
													<div className="item_title">STEP</div>
													<div className="item_details">{(notification?.metaData as any)?.step?.name}</div>
												</div>
												<div className="col-md-6">
													<div className="item_title">STATUS</div>
													<div className="item_details">{DisciplineStatusForHumansEnum[((notification?.metaData as any)?.status as DisciplineStatusType)]}</div>
												</div>
											</div>

											<div className="row">
												<div className="col-md-4">
													<div className="item_title">HR APPROVAL</div>
													<div className="item_details">{(notification?.metaData as any)?.step?.isHRApprovalRequired ? 'Required' : 'Not Required'}</div>
												</div>
												<div className="col-md-4">
													<div className="item_title">SIGNED</div>
													<div className="item_details">{(notification?.metaData as any)?.isSigned ? 'Signed' : 'Not Signed'}</div>
												</div>
												<div className="col-md-4">
													<div className="item_title">TYPE</div>
													<div className="item_details">{(notification?.metaData as any)?.connectionType === ConnectionTypeEnum.Discipline ? 'Corrective Action': (notification?.metaData as any)?.connectionType}</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="item_title">MESSAGE</div>
											<div className="item_details">{notification?.message}</div>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer d-flex flex-row justify-content-between">
								<button type="button"
											className='btn btn-outline-danger px-6'
											onClick={handleDismissNotification}>Dismiss</button>
								{!isRecordDeleted && !isErrored && (
									<button
										className="btn btn-primary align-self-start"
										type="button"
										onClick={() => handleShowDisciplineModal()}
									>
										View
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
				<button type="button"
								ref={closeModalRef}
								data-bs-dismiss="modal"
								className='d-none'
				/>
			</div>
		</>
	);
}
