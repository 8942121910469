import { useFormik } from 'formik';
import { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { SkeButtonLoading } from '../../../../../../common/components/ButtonLoading';
import { RootState } from '../../../../../../setup';
import { updateCompany } from '../../../../../CRUD/SettingsCRUD';
import { countries } from '../../../../../data/country_list';
import { UserModel } from '../../../../../models';
import * as auth from '../../../../../modules/auth/redux/AuthRedux';

const companyProfileSettingsSchema = Yup.object().shape({
	company: Yup.string().required('Company name is required'),
	country: Yup.string().required('Country is required'),
	limit: Yup.string().required('At risk level is required'),
});

export const CompanyProfileSettings = () => {
	const dispatch = useDispatch();
	const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;
	const token: string = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual) as string;

	const initialValues = {
		company: user.company?.name,
		companySite: user.company?.website,
		country: user.company?.country,
		limit: user.company?.atRiskPointLimit,
	};

	const [isLoading, setIsLoading] = useState(false);
	const formik = useFormik({
		initialValues,
		validationSchema: companyProfileSettingsSchema,
		onSubmit: async (values) => {
			if (user.company) {
				setIsLoading(true);
				try {
					await updateCompany(user.company?.id, {
						name: values.company,
						website: values.companySite,
						country: values.country,
						atRiskPointLimit: values.limit,
					}, token);

					dispatch(auth.actions.setUser(
						{
							...user,
							company: {
								...user.company,
								name: values.company!,
								website: values.companySite!,
								country: values.country!,
								atRiskPointLimit: values.limit!,
							},
						},
					));
				} catch (error: any) {
					console.log(error);
					toast.error(error?.response?.data?.message || 'An error has occurred', {
						position: 'top-right',
						theme: 'colored',
						autoClose: 3500,
					});
				} finally {
					setIsLoading(false);
				}
			}
		},
	});

	return (
		<div className="card mb-5 mb-xl-10">
			<div
				className="card-header border-0 cursor-pointer"
				role="button"
				data-bs-toggle="collapse"
				data-bs-target="#kt_account_profile_details"
				aria-expanded="true"
				aria-controls="kt_account_profile_details"
			>
				<div className="card-title m-0">
					<h3 className="fw-bolder m-0">Company Profile Details</h3>
				</div>
			</div>

			<div
				id="kt_account_profile_details"
				className="collapse show">
				<form
					onSubmit={formik.handleSubmit}
					noValidate
					className="form">
					<div className="card-body border-top p-9">

						<div className="row mb-6">
							<label className="col-lg-4 col-form-label required fw-bold fs-6">Company Name</label>

							<div className="col-lg-8 fv-row">
								<input
									type="text"
									className="form-control form-control-lg form-control-solid"
									placeholder="Company name"
									{...formik.getFieldProps('company')}
								/>
								{formik.touched.company && formik.errors.company && (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">{formik.errors.company}</div>
									</div>
								)}
							</div>
						</div>

						<div className="row mb-6">
							<label className="col-lg-4 col-form-label required fw-bold fs-6">At Risk Level</label>

							<div className="col-lg-8 fv-row">
								<input
									type="number"
									step={0.1}
									className="form-control form-control-lg form-control-solid"
									placeholder="At Risk Points Limit"
									{...formik.getFieldProps('limit')}
								/>
								{formik.touched.limit && formik.errors.limit && (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">{formik.errors.limit}</div>
									</div>
								)}
							</div>
						</div>

						<div className="row mb-6">
							<label className="col-lg-4 col-form-label fw-bold fs-6">
								<span className="required">Company Site</span>
							</label>

							<div className="col-lg-8 fv-row">
								<input
									type="text"
									className="form-control form-control-lg form-control-solid"
									placeholder="Company website"
									{...formik.getFieldProps('companySite')}
								/>
								{formik.touched.companySite && formik.errors.companySite && (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">{formik.errors.companySite}</div>
									</div>
								)}
							</div>
						</div>

						<div className="row mb-6">
							<label className="col-lg-4 col-form-label fw-bold fs-6">
								<span className="required">Country</span>
							</label>

							<div className="col-lg-8 fv-row">
								<select
									className="form-select form-select-solid form-select-lg fw-bold"
									{...formik.getFieldProps('country')}
								>
									<option value="">Select a Country...</option>
									{
										countries.map((country, i) => {
											return <option
												key={i}
												value={country}>{country}</option>;
										})
									}
								</select>
								{formik.touched.country && formik.errors.country && (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">{formik.errors.country}</div>
									</div>
								)}
							</div>
						</div>
					</div>

					<div className="card-footer d-flex justify-content-end py-6 px-9">
						<SkeButtonLoading
							isLoading={isLoading}
							loadingMsg={'Please Wait...'}
							type={'submit'}
						>
							Save Changes
						</SkeButtonLoading>
					</div>
				</form>
			</div>
		</div>
	);
};
