import { Navigate, Route, Routes } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../_library/layout/core'
import { Overview } from './overview/Overview'
import { SupervisorAccountHeader } from './SupervisorAccountHeader'
import { UserSettings } from "./settings/UserSettings";

const accountBreadCrumbs: Array<PageLink> = [
    {
        title: 'Home',
        path: '',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Account',
        path: '',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

export function SupervisorAccountPage() {
    return (
        <>
            <SupervisorAccountHeader />
            <Routes>
                <Route path='overview' element={
                    <>
                        <PageTitle breadcrumbs={accountBreadCrumbs}>Account Overview</PageTitle>
                        <Overview />
                    </>
                }>
                </Route>
                <Route path='user-settings' element={
                    <>
                        <PageTitle breadcrumbs={accountBreadCrumbs}>User Settings</PageTitle>
                        <UserSettings />
                    </>
                }>
                </Route>
                <Route
                  path='*'
                  element={<Navigate to='overview' />}
                />
            </Routes>
        </>
    )
}
