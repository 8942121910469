import { skipToken } from '@reduxjs/toolkit/query';
import { compact, concat, get } from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { sortDateDescending } from '../../../_library/helpers/sortDate';
import { PageLink, PageTitle } from '../../../_library/layout/core';
import { SkeBeta } from '../../../common/components/beta';
import { ModalDisplayMode } from '../../../common/interfaces/modal.model';
import { QueryOperators } from '../../../common/interfaces/response-format.model';
import { AiReviewModalState, AiReviewModalStateUnion } from '../../../features/ai/review/ai.review-modal.slice';
import { AIReviewModal } from '../../../features/ai/review/modal/AIReviewModal';
import { GetConnectionPayload, getConnections } from '../../../features/connection/ConnectionCRUD';
import { ConnectionTypeEnumWithCa } from '../../../features/connection/interfaces/connection.model';
import { VwConnection, VwConnectionDetails } from '../../../features/connection/interfaces/vw_connection.model';
import {
	DisciplineModalCreateState,
	disciplineModalSlice, DisciplineModalState,
	DisciplineModalStateUnion,
} from '../../../features/discipline/discipline-modal.slice'
import {
	Recognition,
	RecognitionsGetAllApiRequestModel,
} from '../../../features/recognition/interfaces/recognition.model';
import {
	RecognitionModalCreateState,
	recognitionModalSlice,
} from '../../../features/recognition/recognition-modal.slice';
import { recognitionSlice } from '../../../features/recognition/recognition.api';
import { isAiReviewEnabled } from '../../../features/settings/utils/settings.util';
import { teamSlice } from '../../../features/team/team.api';
import { RootState } from '../../../setup';
import { Icon } from '../../components/icons/Icon';
import { Download, OpenAI } from '../../components/icons/IconList';
import { getEmployee, getEmployeeAttendances, getEmployeeCoaching, getEmployeeDisciplines } from '../../CRUD/TeamCRUD';
import { UserModel } from '../../models';
import { AttendanceGetParamsModel } from '../../modules/attendance/models/AttendanceGetParamsModel';
import { SHORT_DATE } from '../../modules/date/DateFormat.const';
import { Employee } from '../../modules/employee/models/Employee.model';
import { AttendanceType } from '../../types/AttendanceType';
import { CoachingType } from '../../types/CoachingType';
import { ConnectionType, ConnectionTypeEnum, ConnectionUnion } from '../../types/ConnectionType';
import { DisciplineType } from '../../types/DisciplineType';
import { EmployeeType, TeamType } from '../../types/EmployeeType';
import { PerformanceReportConfigType } from '../../types/PerformanceReportConfigType';
import { SortDateEnum } from '../../types/SortDateEnum';
import { AttendanceModal } from '../connections/attendance/AttendanceModal';
import { EmployeePerformanceCard } from '../dashboard/_modals/employee/EmployeePerformanceCard';
import { EmployeePerformanceCardConfig } from '../dashboard/_modals/employee/EmployeePerformanceCardConfig';
import { ChangeTeamModal } from './components/ChangeTeamModal';
import { EmployeeCardInformation } from './components/EmployeeCardInformation';
import { TeamCard } from './components/TeamCard';
import './TeamMemberView.scss';
import {disciplineSlice} from '../../../features/discipline/discipline.api'
import {Discipline} from '../../../features/discipline/interfaces/discipline.model'
import {
	CoachingModalCreateState,
	coachingModalSlice, CoachingModalState,
	CoachingModalStateUnion,
} from '../../../features/coaching/coaching-modal.slice'
import { SkePopover } from "../../../common/components/Popover";
import {coachingSlice} from '../../../features/coaching/coaching.api'
import {Coaching} from '../../../features/coaching/interfaces/coaching.model'


type RouteParams = {
	teamId: string;
	id: string;
}

const parseConnectionsForCalendars = (item: (ConnectionUnion|Recognition|Coaching|Discipline|VwConnection<VwConnectionDetails>)) => {
	let type: ConnectionType | undefined = get(item, 'connectionType', get(item, 'connection_type', undefined));
	if (type === 'ATTENDANCE' && (item as AttendanceType).reason.isExcused) {
		type = ConnectionTypeEnum.Excused;
	}
	return {
		type,
		// needed for monthly calendar
		yearMonth: item.date?.slice(0, 7),
		date: item.date,
		// both needed for yearly calendar
		startDate: moment(item.date).toDate(),
		endDate: moment(item.date).toDate(),
	};
};

export function TeamMemberView() {
	const {
		teamId,
		id,
	} = useParams<RouteParams>();
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel;
	const [employee, setEmployee] = useState<EmployeeType>();
	const [attendances, setAttendances] = useState<AttendanceType[]>([]);
	const [coachingSources, setCoachingSources] = useState<Array<any>>([]);
	const [recognitionSources, setRecognitionSources] = useState<Array<any>>([]);
	const [attendanceSources, setAttendanceSources] = useState<Array<any>>([]);
	const [disciplineSources, setDisciplineSources] = useState<Array<any>>([]);

	const [genericConnectionSources, setGenericConnectionSources] = useState<Array<any>>([]);
	const [combinedConnections, setCombinedConnections] = useState<(AttendanceType|CoachingType|Discipline|Recognition|VwConnection<VwConnectionDetails>)[]>([]);
	const [showAIReviewModal, setShowAIReviewModal] = useState<boolean>(false);
	const [showInstantPerformanceReport, setShowInstantPerformanceReport] = useState<boolean>(false);
	const [showEmployeePerformanceModal, setShowEmployeePerformanceModal] = useState<boolean>(false);
	const [isLoadingConnections, setIsLoadingConnections] = useState<boolean>(true);

	// for display on the employee profile
	const [connectionStartDate, setConnectionStartDate] = useState<Date>(moment().subtract(1, 'year').toDate());
	// grabbing 1 month out for Corrective Actions scheduled in the future
	const [connectionEndDate, setConnectionEndDate] = useState<Date>(moment().add(1, 'month').toDate());

	// for display on the instant performance report
	const [reportStartDate, setReportStartDate] = useState<Date>(moment().subtract(1, 'year').toDate());
	const [reportEndDate, setReportEndDate] = useState<Date>(moment().add(1, 'day').toDate());
	const [reportAttendances, setReportAttendances] = useState<AttendanceType[]>([]);
	const [reportCoachings, setReportCoachings] = useState<CoachingType[]>([]);
	const [reportRecognitions, setReportRecognitions] = useState<Recognition[]>([]);
	const [reportDisciplines, setReportDisciplines] = useState<Discipline[]>([]);
	const [performanceReportConfig, setPerformanceReportConfig] = useState<PerformanceReportConfigType>({
		attendance: true,
		excused: false,
		coaching: true,
		combineConnections: false,
		discipline: true,
		recognition: true,
		startDate: reportStartDate,
		endDate: reportEndDate,
	});
	const { data: teams } = teamSlice.useGetAllTeamsQuery();
	const { data: teamInfo } = teamSlice.useGetOneTeamQuery(teamId ? {team_id: +teamId} : skipToken);
	const connectionsFilter: RecognitionsGetAllApiRequestModel = {
		// `id` has to be here as it's non-optional on the route
		employeeId: +id!,
		fromDate: moment(connectionStartDate).format(SHORT_DATE),
		toDate: moment(connectionEndDate).format(SHORT_DATE),
		limit: 100,
	}
	const { data: recognitionsResponse, fulfilledTimeStamp: rcgTimestamp, refetch: refreshRecognitions } = recognitionSlice.useGetRecognitionsQuery(connectionsFilter, {refetchOnMountOrArgChange: true});
	const { data: disciplinesResponse, fulfilledTimeStamp: discTimestamp, refetch: refreshDisciplines } = disciplineSlice.useGetDisciplinesQuery(connectionsFilter, {refetchOnMountOrArgChange: true});
	const { data: coachingsResponse, fulfilledTimeStamp: coachingTimestamp, refetch: refreshCoachings } = coachingSlice.useGetCoachingsQuery(connectionsFilter, {refetchOnMountOrArgChange: true});
	const disciplineModalState = useSelector<RootState>(state => state.disciplineModal) as DisciplineModalStateUnion & Pick<DisciplineModalState, 'showModal' | 'mutationRan'>;
	const coachingModalState = useSelector<RootState>(state => state.coachingModal) as CoachingModalStateUnion & Pick<CoachingModalState, 'showModal' | 'mutationRan'>;
	const [genericConnections, setGenericConnections] = useState<VwConnection<VwConnectionDetails>[]>([]);
	const [getReportRecognitionsQuery] = recognitionSlice.useLazyGetRecognitionsQuery();
	const [getReportDisciplinesQuery] = disciplineSlice.useLazyGetDisciplinesQuery();
	const [getReportCoachingsQuery] = coachingSlice.useLazyGetCoachingsQuery();
	const aiReviewModalState = useSelector<RootState>(state => state.aiReviewModal) as AiReviewModalStateUnion & Pick<AiReviewModalState, 'showModal' | 'mutationRan'>;

	const dispatch = useDispatch();
	const handleNewRecognitionClick = () => {
		const payload: RecognitionModalCreateState = {
			mode: ModalDisplayMode.Create,
			employee: (employee as unknown as Employee),
		}
		dispatch(recognitionModalSlice.actions.show(payload));
	};

	const handleNewDisciplineClick = () => {
		const payload: DisciplineModalCreateState = {
			mode: ModalDisplayMode.Create,
			employee: (employee as unknown as Employee),
		}
		dispatch(disciplineModalSlice.actions.show(payload));
	};

	const handleNewCoachingClick = () => {
		const payload: CoachingModalCreateState = {
			mode: ModalDisplayMode.Create,
			employee: (employee as unknown as Employee),
		}
		dispatch(coachingModalSlice.actions.show(payload));
	};

	useEffect(() => {
		if(!disciplineModalState.showModal && disciplineModalState.mutationRan){
			refreshDisciplines();
		}
	}, [disciplineModalState])

	useEffect(() => {
		if(!coachingModalState.showModal && coachingModalState.mutationRan){
			refreshCoachings();
		}
	}, [coachingModalState])

	useEffect(() => {
		const rcg = recognitionsResponse?.items || [];
		setReportRecognitions(rcg);
		setRecognitionSources(rcg.map(parseConnectionsForCalendars));
	}, [recognitionsResponse, rcgTimestamp]);

	useEffect(() => {
		const disc = disciplinesResponse?.items || [];
		setReportDisciplines(disc);
		setDisciplineSources(disc.map(parseConnectionsForCalendars));
	}, [disciplinesResponse, discTimestamp]);

	useEffect(() => {
		const coach = coachingsResponse?.items || [];
		setReportCoachings(coach);
		setCoachingSources(coach.map(parseConnectionsForCalendars));
	}, [coachingsResponse, coachingTimestamp]);

	const refreshEmployeeData = () => {
		if (!id) {
			return console.error('No employee id set');
		}
		getEmployee(+id, token)
			.then(({ data }) => {
				setEmployee(data);
			})
			.catch(err => {
				console.log(err.response);
			});
	};

	useEffect(() => {
		if (!!token && !!id) {
			refreshEmployeeData();
			loadConnectionsForHistory();
		}
	}, [id, token, aiReviewModalState]);

	const loadConnectionsForHistory = async () => {
		setIsLoadingConnections(true);
		const attReq = getAttendances(connectionStartDate, connectionEndDate);
		const flt: GetConnectionPayload = {
			filters: [
				{
					field: 'employee_ids',
					value: [id],
					operator: QueryOperators.Equals,
				},
				{
					field: 'after_date',
					value: moment(connectionStartDate).format(SHORT_DATE),
					operator: QueryOperators.GreaterThan,
				},
				{
					field: 'before_date',
					value: moment(connectionEndDate).format(SHORT_DATE),
					operator: QueryOperators.LessThan,
				},
				{
					field: 'connection_type_array',
					value: [ConnectionTypeEnumWithCa.Review],
					operator: QueryOperators.Equals,
				},
			],
			page: 1,
			pageSize: 100,
			sort: [{
				field: 'date',
				dir: 'desc',
				position: 0,
			}],
			detailed: true,
		};
		const genericConnectionReq = getConnections(token, flt);

		await Promise.all([
			attReq,
			genericConnectionReq,
			refreshRecognitions(),
			refreshDisciplines(),
			refreshCoachings(),
		]).then(([att, gen]) => {
			setAttendanceSources(att.map(parseConnectionsForCalendars));
			setAttendances(att);
			setGenericConnectionSources(gen.data.results.items.map(parseConnectionsForCalendars));
			setGenericConnections(gen.data.results.items);
			setIsLoadingConnections(false);
		});
	};

	const getAttendances = async (fromDate: Date, toDate: Date): Promise<AttendanceType[]> => {
		const opts: AttendanceGetParamsModel = {
			fromDate: moment(fromDate).format(SHORT_DATE),
			toDate: moment(toDate).format(SHORT_DATE),
			sort: SortDateEnum.Desc,
			includeFuture: 'true',
			limit: 100,
		};
		if (!id) {
			throw new Error('No employee id set');
		}
		try {
			const { data } = await getEmployeeAttendances(+id, token, opts);
			return data.items;
		} catch (err: any) {
			console.log(err.response);
			return [];
		}

	};

	const refreshData = async () => {
		refreshEmployeeData();
		await loadConnectionsForHistory();
	};

	useEffect(() => {
		const newConnections: (AttendanceType|Recognition|Discipline|Coaching|VwConnection<VwConnectionDetails>)[] = compact(
			concat<(AttendanceType|Recognition|Discipline|Coaching|undefined|VwConnection<VwConnectionDetails>)>(
				attendances,
				recognitionsResponse?.items,
				disciplinesResponse?.items,
				coachingsResponse?.items,
				genericConnections,
			)
		);
		newConnections.sort(sortDateDescending);
		setCombinedConnections(newConnections);
	}, [attendances, recognitionSources, disciplineSources, coachingSources, genericConnections]);

	const getTeamLink = (): PageLink[] => {
		if (employee && employee.teams?.[0]) {
			return [
				{
					isActive: true,
					path: `/teams/show/${employee.teams[0].id}`,
					title: employee.teams[0].name,

				},
			];
		}
		return [];
	};

	const handleGenerateReport = async (config: PerformanceReportConfigType) => {
		Promise.all([
						 getAttendances(moment(config.startDate).toDate(), moment(config.endDate).toDate()),
						 getReportRecognitionsQuery({
							 employeeId: +id!,
							 fromDate: moment(config.startDate).format(SHORT_DATE),
							 toDate: moment(config.endDate).format(SHORT_DATE),
							 sort: SortDateEnum.Desc,
							 limit: 100
						 }).unwrap(),
			       getReportDisciplinesQuery({
							 employeeId: +id!,
							 fromDate: moment(config.startDate).format(SHORT_DATE),
							 toDate: moment(config.endDate).format(SHORT_DATE),
							 sort: SortDateEnum.Desc,
							 limit: 100
						 }).unwrap(),
			       getReportCoachingsQuery({
							 employeeId: +id!,
							 fromDate: moment(config.startDate).format(SHORT_DATE),
							 toDate: moment(config.endDate).format(SHORT_DATE),
							 sort: SortDateEnum.Desc,
							 limit: 100
						 }).unwrap(),
					 ])
					 .then(val => {
						 const [att, recog, disc, coach] = val;

						 setReportAttendances(att);
						 setReportRecognitions(recog?.items);
						 setReportDisciplines(disc?.items);
						 setReportCoachings(coach.items);
					 })
					 .catch(err => {
						 console.log('Error loading connections for Instant Performance Report', err);
					 })
					 .finally(() => {
						 setShowInstantPerformanceReport(false);
						 setShowEmployeePerformanceModal(true);
					 })
	}

	//AI review modal
	const handleShowAIReviewModal = ()=> {
		setShowAIReviewModal(true)
	}
	const handleAIReviewModalClose = (action: boolean) => {
		setShowAIReviewModal(action);
	}
	const handleAIReviewModalSubmit = () => {
		setShowAIReviewModal(false);
	}

	return (
		<>
			<PageTitle breadcrumbs={getTeamLink()}>Team Member</PageTitle>
			<div className="d-flex flex-column flex-lg-row">
				{employee?.id && <TeamCard employee={employee}
																	 refreshEmployeeData={refreshEmployeeData} />}
				<div className="flex-lg-container-fluid ms-lg-15">
						<div className="d-flex mb-8 flex-row flex-wrap gap-1 align-items-center align-items-md-start">
							{!employee?.isTerminated && (
								<>
									<button
										type="button"
										className="btn btn-outline btn-outline-primary fs-6 btn-active-light-primary px-5 flex-grow-1 flex-md-grow-0 d-block d-md-inline-block w-100 w-md-auto m-1"
										onClick={handleNewRecognitionClick}
									>
										<i className="fas fa-award text-primary"></i>
										New Recognition
									</button>

									<button
										type="button"
										className="btn btn-outline btn-outline-primary fs-6 btn-active-light-primary px-5 flex-grow-1 flex-md-grow-0 d-block d-md-inline-block w-100 w-md-auto m-1"
										onClick={() => handleNewCoachingClick()}
									>
										<i className="fas fa-chalkboard-teacher text-primary"></i>
										New Coaching
									</button>

									<button
										type="button"
										className="btn btn-outline btn-outline-primary fs-6 btn-active-light-primary px-5 flex-grow-1 flex-md-grow-0 d-block d-md-inline-block w-100 w-md-auto m-1"
										data-bs-toggle="modal"
										data-bs-target='#new-attendance-from-team-member-view'
									>
										<i className="bi bi-calendar2-x fs-5 text-primary"></i>
										New Attendance
									</button>

									<button
										type="button"
										className="btn btn-outline btn-outline-primary fs-6 btn-active-light-primary px-5 flex-grow-1 flex-md-grow-0 d-block d-md-inline-block w-100 w-md-auto m-1"
										onClick={() => handleNewDisciplineClick()}
									>
										<i className="bi bi-person-fill-exclamation fs-5 text-primary"></i>
										New Corrective Action
									</button>
									<div className="d-flex flex-grow-1 w-100">
										<div className="d-flex-grow-1 flex-md-grow-0 d-block d-md-inline-block w-100 w-md-auto m-1">
											<SkePopover
												className="mw-400px"
												closeOnContentClick
												placement="auto-start"
												trigger={['click']}
												triggerElement={
													<button className="btn btn-outline btn-outline-primary btn-active-light-primary d-block w-100">
														<i className="bi bi-three-dots p-0 text-primary fs-5"></i>
													</button>
												}
											>
												<div className="d-flex flex-column gap-2">
													{isAiReviewEnabled(user) && (
														<button
															type="button"
															className="btn btn-outline btn-outline-primary fs-6 btn-active-light-primary px-5 text-start d-flex align-items-center gap-2"
															onClick={() => handleShowAIReviewModal()}
														>
															<i className="bi bi-magic text-primary"></i>
															<span>AI Performance Review<SkeBeta className="ms-2 me-0"></SkeBeta></span>
														</button>
													)}
													<button
														type="button"
														className="btn btn-outline btn-outline-primary fs-6 btn-active-light-primary px-5 text-start d-flex align-items-center gap-2"
														onClick={() => setShowInstantPerformanceReport(true)}
													>
														<i className="bi bi-download fs-4 text-primary"></i>
														<span>Instant Performance Report</span>
													</button>
												</div>
											</SkePopover>
										</div>
									</div>
								</>
							)}
						</div>
					{/*end:::Tabs*/}
					{/*begin:::Tab content*/}

					<EmployeeCardInformation
						attendanceSources={attendanceSources}
						recognitionSources={recognitionSources}
						disciplineSources={disciplineSources}
						coachingSources={coachingSources}
						genericSources={genericConnectionSources}
						combinedConnections={combinedConnections}
						isLoadingConnections={isLoadingConnections}
						employee={employee!}
						refresh={refreshData}
						showAiReview={!!get(employee, 'company.settings.values', '')}
					/>
					{/*end:::Tab content*/}
				</div>
			</div>
			{employee?.id &&
				<>
					<EmployeePerformanceCard
						employee={employee}
						attendances={reportAttendances}
						recognitions={reportRecognitions || []}
						disciplines={reportDisciplines || []}
						coachings={reportCoachings || []}
						config={performanceReportConfig}
						show={showEmployeePerformanceModal}
						onClose={() => setShowEmployeePerformanceModal(false)}
					/>
					<EmployeePerformanceCardConfig
						config={performanceReportConfig}
						setConfig={setPerformanceReportConfig}
						onGenerateReport={handleGenerateReport}
						show={showInstantPerformanceReport}
						onClose={() => setShowInstantPerformanceReport(false)}
					/>
					{employee && <AttendanceModal
						employee={employee}
						modalId='new-attendance-from-team-member-view'
						mode="new"
						refresh={refreshData}
					/>}
					<ChangeTeamModal
						employee={employee}
						teams={(teams as unknown as TeamType[])}
						refresh={refreshEmployeeData}
					/>
					<AIReviewModal
						show={showAIReviewModal}
						employee={(employee as unknown as Employee)}
						token={token}
						onClose={handleAIReviewModalClose}
						onSubmit={handleAIReviewModalSubmit}
					/>
				</>
			}
		</>
	);
}
