import {
	addMonths,
	eachMonthOfInterval,
	endOfMonth,
	format,
	previousMonday,
	previousSunday,
	startOfMonth,
	subMonths,
} from 'date-fns';
import { DateFormatUsingDateFns } from '../../app/modules/date/DateFormat.const';

export const getMonthMetadata = (opts: MonthMetadataForRangeOpts | MonthMetadataForLookaroundOpts) : MonthMetadataOutput[] => {
	let months: MonthMetadataOutput[];
	let range: Date[];
	const effectiveDate = opts.effectiveDate || new Date();
	if ('monthCount' in opts) {
		if (opts.direction === 'PAST') {
			range = eachMonthOfInterval({
			// subtracting one as we're including this month already
				start: subMonths(effectiveDate, opts.monthCount - 1),
				end: new Date(),
			})
		} else {
			range = eachMonthOfInterval({
				start: effectiveDate,
				// subtracting one as we're including this month already
				end: addMonths(effectiveDate, opts.monthCount - 1)
			})
		}
	} else {
		range = eachMonthOfInterval({
			start: opts.startDate,
			end: opts.endDate,
		})
	}

	months = range.map(itm => ({
		startDate: startOfMonth(itm),
		endDate: endOfMonth(itm),
		yearMonth: format(itm, DateFormatUsingDateFns.FourYearTwoMonth),
	}));

	if (opts.sort === 'DESC') {
		months.reverse();
	}

	return months;
};

	export const getPreviousMonday = (date = new Date()): Date => {
		return previousMonday(date);
	};

	export const getPreviousSunday = (date = new Date()): Date => {
		return previousSunday(date);
	};

	export const getFirstDayPreviousMonth = (date = new Date()): Date => {
		return startOfMonth(subMonths(date, 1));
	}

export const getLastDayPreviousMonth = (date = new Date): Date => {
	return endOfMonth(subMonths(date, 1));
};

export const isLeapDay = (month: number, day: number): boolean => {
	return (month === 2 && day === 29);
}

export const handleLeapDay = (year: number, month: number, day: number): Date => {
	if (isLeapDay(month, day)) {
		return new Date(year, 1, 1);
	}
	return new Date(year, month - 1, day);
}

export interface MonthMetadataOpts {
	sort: 'ASC' | 'DESC';
	effectiveDate?: Date;
}

export interface MonthMetadataForRangeOpts extends MonthMetadataOpts {
	startDate: Date;
	endDate: Date;
}

export interface MonthMetadataForLookaroundOpts extends MonthMetadataOpts {
	monthCount: number;
	direction: 'FUTURE' | 'PAST';
}

export interface MonthMetadataOutput {
	startDate: Date;
	endDate: Date;
	/**
	 * Format: YYYY-MM
	 */
	yearMonth: string;
}
