import { get } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react'
import { AttendanceReasonModel } from '../../../../../features/attendance/interfaces/attendance-reason.model';
import { Recognition } from '../../../../../features/recognition/interfaces/recognition.model';
import { AttendanceModel } from '../../../../models';
import { getMonthsForPeriod } from '../../../../modules/date/utils/DateHelpers';
import { templatesList } from "../../../../modules/discipline/models/DisciplineTemplates";
import { EmployeeType } from '../../../../types/EmployeeType'
import './EmployeeCardContent.scss'
import Calendar, { CalendarTileProperties } from 'react-calendar'
import moment from 'moment'
import { AttendanceType } from '../../../../types/AttendanceType'
import { CoachingType } from '../../../../types/CoachingType'
import { RecognitionType } from '../../../../types/RecognitionType'
import { DisciplineType } from '../../../../types/DisciplineType'
import { PerformanceReportConfigType } from '../../../../types/PerformanceReportConfigType';
import { sortDateAscending } from '../../../../../_library/helpers/sortDate';
import { ConnectionType, ConnectionTypeEnum, ConnectionUnion } from '../../../../types/ConnectionType';
import { SHORT_DATE } from '../../../../modules/date/DateFormat.const';
import {Discipline, DisciplineStatusEnum} from '../../../../../features/discipline/interfaces/discipline.model'

interface Props {
	employee: EmployeeType,
	attendances: AttendanceType[]
	coachings: CoachingType[]
	recognitions: Recognition[]
	disciplines: Discipline[]
	config: PerformanceReportConfigType
}

export const EmployeeCardContent = React.forwardRef<HTMLDivElement, Props>(({
	employee,
	attendances,
	coachings,
	recognitions = [],
	disciplines,
	config,
}, ref) => {
	const [combinedConnections, setCombinedConnections] = useState<(Exclude<ConnectionUnion, RecognitionType>|Recognition)[]>([])

	useEffect(() => {
		let combined: (Exclude<ConnectionUnion, RecognitionType>|Recognition)[] = [];
		if (config.coaching) {
			combined.push(...coachings);
		}
		if (config.recognition) {
			combined.push(...recognitions);
		}
		if (config.discipline) {
			combined.push(...(disciplines as unknown as DisciplineType[]));
		}
		if (config.attendance) {
			// put all attendances together
			if (config.excused) {
				combined.push(...attendances);
			} else {
				combined.push(...attendances.filter(att => !att.reason.isExcused));
			}
		} else if (config.excused) {
			// or process just excused ones
			combined.push(...attendances.filter(att => att.reason.isExcused));
		}
		combined.sort(sortDateAscending);
		setCombinedConnections(combined);
	}, [attendances, coachings, recognitions, disciplines, config])

	let attendancePoints = 0;
	let unExcusedCount = 0;
	let excusedCount = 0;
	let fmlaCount = 0;
	let fmlaHoursUsed = 0;
	attendances.map( att =>{
		if(!att.reason.isExcused ){
			attendancePoints += get((att as AttendanceModel), 'pointsAssigned', 0);
			unExcusedCount++;
			return;
		}

		if((att.reason as AttendanceReasonModel).is_fmla ){
			fmlaCount++;
			fmlaHoursUsed += get((att as AttendanceModel), 'fmla_hours', 0);
		} else{
			excusedCount++;
		}

	})

	const printDate = moment().format(SHORT_DATE);
	const todaysDate = moment();
	const startDate = moment(config.startDate);
	const endDate = moment(config.endDate);

	const hasDisciplineBeenIssued = (disc: Discipline) => {
		// only counting disciplines the employee is aware of
		return ![
			DisciplineStatusEnum.DisciplineDeclined,
			DisciplineStatusEnum.Draft,
			DisciplineStatusEnum.RequestedChange,
			DisciplineStatusEnum.Approved,].includes(disc.status)
	}

	const parseConnectionsForCalendars = (item: (Exclude<ConnectionUnion, RecognitionType>|Recognition)) => {
		let type: ConnectionType = item.connectionType!;
		if (type === 'ATTENDANCE' && (item as AttendanceType).reason.isExcused) {
			type = ConnectionTypeEnum.Excused;
		}
    return {
      type,
      // needed for monthly calendar
      yearMonth: item.date?.slice(0, 7),
      date: item.date,
      // both needed for yearly calendar
      // startDate: moment(item.date).toDate(),
      // endDate: moment(item.date).toDate(),
    }
  }

	return (
		<div ref={ref} className="">
			<div
				className="card_content_wrapper"
				id="instant-performance-report"
			>
				<style>
					{`
						@page {
							margin-top: 0.5in;
						}

						@page:right{
							@bottom-right {
								content: "Page " counter(page) " of " counter(pages);;
							}
						}
					`}
				</style>
				<div className="card_header d-flex">
					<div className="d-flex flex-row justify-content-between col-12 align-items-center">
						<h1>Instant Performance Report</h1>
						<span className="text-muted d-inline-flex fs-4 align-self-center">{moment(config.startDate).format(SHORT_DATE)} to {moment(config.endDate).format(SHORT_DATE)}</span>
					</div>
				</div>
				<div className="card_body">
					<h3 className='text-muted'>Employee Detail</h3>
					<table className="table table-bordered">
						<tbody>
							<tr>
								<td className='table-active'>Employee Name</td>
								<td className=''>{employee.firstName} {employee.lastName}</td>
								<td className='table-active'>Date of Review</td>
								<td className=''>{printDate}</td>
								{config.excused && (
									<>
										<td className="table-active">FMLA Hours Left</td>
										<td className=''>{employee.fmla_hours_left}</td>
									</>
								)}
						</tr>
						<tr>
							<td className="table-active">Employee ID</td>
							<td className="">{employee.employeeId}</td>
							<td className="table-active">Team</td>
							<td className="">{employee?.teams?.map(team => team.name).join(', ')}</td>
							{config.excused && (
								<>
									<td className="table-active">FMLA Hours Used</td>
									<td className="">{fmlaHoursUsed}</td>
								</>
							)}
						</tr>
						</tbody>
					</table>


					<h3 className="text-muted">Connection Summary</h3>
					<table className="table table-bordered performance-table">
						<tbody>
						<tr>
							{
								config.recognition && (
									<>
										<td className="table-active text-capitalize">
											Recognitions Count
										</td>
										<td>
											{recognitions.length}
										</td>
									</>
								)
							}
							{
								config.coaching && (
									<>
										<td className="table-active text-capitalize">
											Coachings Count
										</td>
										<td>
											{coachings.length}
										</td>
									</>
								)
							}
							{
								config.discipline && (
									<>
										<td className="table-active text-capitalize">
											Corrective Actions Count
										</td>
										<td>
											{disciplines.filter(hasDisciplineBeenIssued).length}
										</td>
									</>
								)
							}
							{
								config.attendance && (
									<>
										<td className="table-active text-capitalize">
											Attendances Count (ALL)
										</td>
										<td>
											{attendances.length}
										</td>
									</>
								)
							}
						</tr>
						<tr>
							{
								config.attendance && (
									<>
										<td className="table-active text-capitalize">
											Points Accumulated
										</td>
										<td>
											{attendancePoints}
										</td>
										<td className="table-active text-capitalize">
											Unexcused Count
										</td>
										<td>
											{unExcusedCount}
										</td>
									</>
								)
							}
							{
								config.excused && (
									<>
										<td className="table-active text-capitalize">
											Excused Count
										</td>
										<td>
											{excusedCount}
										</td>
										<td className="table-active text-capitalize">
											FMLA Count
										</td>
										<td>
											{fmlaCount}
										</td>
									</>
								)
							}
						</tr>
						</tbody>
					</table>

					<h3 className="text-muted">Connection History</h3>
					<div className="connection-log-wrapper">
					{/*begin::combined table*/}
					{
						config.combineConnections &&
						<table className="table table-bordered connection-table">
							<thead>
								<tr>
									<th className='table-active fw-bolder' colSpan={6}>All Connections</th>
								</tr>
								<tr>
									<th scope="col" className="w-10">Date</th>
									<th scope="col" className="w-15">Type</th>
									<th scope="col" className="w-20">Category</th>
									<th scope="col" className="w-45">Notes</th>
									<th scope="col" className="w-40">Supervisor Notes</th>
									<th scope="col" className="w-5">Points / FMLA Hours</th>
								</tr>
							</thead>
							<tbody className=' fs-6'>
								{
									combinedConnections.map((item, i) => {
										switch (item.connectionType) {
											case ConnectionTypeEnum.Attendance:
												if ((item as AttendanceType).reason.isExcused) {
													if (!config.excused) {
														return null;
													}

													if (((item as AttendanceType).reason as AttendanceReasonModel).is_fmla) {
														return (
															<tr key={i}>
																<td>{item.date}</td>
																<td>FMLA</td>
																<td>{(item as AttendanceType).occurrence}</td>
																<td>{(item as AttendanceType).reason?.content}</td>
																<td>{(item as AttendanceType).supervisorNote}</td>
																<td>{(item as AttendanceType).fmla_hours} hrs</td>
															</tr>
														)
													}

													return (
														<tr key={i}>
															<td>{item.date}</td>
															<td>EXCUSED</td>
															<td>{(item as AttendanceType).occurrence}</td>
															<td>{(item as AttendanceType).reason?.content}</td>
															<td>{(item as AttendanceType).supervisorNote}</td>
															<td>N/A</td>
														</tr>
													)
												}

												if (!(item as AttendanceType).reason.isExcused) {
													if (!config.attendance) {
														return null;
													}

													return (
														<tr key={i}>
															<td>{item.date}</td>
															<td>UNEXCUSED</td>
															<td>{(item as AttendanceType).occurrence}</td>
															<td>{(item as AttendanceType).reason?.content}</td>
															<td>{(item as AttendanceType).supervisorNote}</td>
															<td>{(item as AttendanceType).pointsAssigned} pts</td>
														</tr>
													)
												}
												return null;
											case ConnectionTypeEnum.Coaching:
												if (config.coaching) {
													return <tr key={i}>
														<td>{item.date}</td>
														<td>{item.connectionType}</td>
														<td>{(item as CoachingType).category?.name}</td>
														<td>{(item as CoachingType).comment}</td>
														<td></td>
														<td></td>
													</tr>
												}
												return null;
											case ConnectionTypeEnum.Recognition:
												if (config.recognition) {
													return (
														<tr
															className='table-row-bordered'
															key={i}>
															<td>{item.date}</td>
															<td>{item.connectionType}</td>
															<td>{(item as unknown as RecognitionType).category?.name}</td>
															<td>{(item as unknown as RecognitionType).message}</td>
															<td></td>
															<td></td>
														</tr>
													)
												}
												return null;
											case ConnectionTypeEnum.Discipline:
												if (config.discipline) {
													const usedTemplate = templatesList.find(tmp => tmp.id === (item as unknown as Discipline).templateId);
													// TODO: because this is getting pulled from an old section of the app, it doesn't convert
													//  the textFields JSON like newer areas.
													const incidentDetails = (!(item as unknown as Discipline).textFields) ? '' :
														JSON.parse(((item as unknown as Discipline).textFields as unknown as string)).incidentDetails;

													return (
														<>
														<tr
															className='table-row-bordered'
															key={i}>
															<td>{item.date}</td>
															<td>Corrective Action</td>
															<td>{(item as DisciplineType).step?.type} ({(item as DisciplineType).step?.name})</td>
															<td>
																{!!usedTemplate && (
																	<span>
																		{usedTemplate.type} &gt; {usedTemplate.category} &gt; {usedTemplate.name}
																	</span>
															)}
															</td>
															<td></td>
															<td></td>
														</tr>
															{!!usedTemplate && incidentDetails &&
																(
																	<tr className="incident-details table-row-bordered">
																		<td
																			className="incident-details"
																			colSpan={5}>
																			<span className="fw-bold">Details: </span>{incidentDetails}

																		</td>
																		<td></td>

																	</tr>
																)}
														</>
													)
												}
												return null;
											default:
												return null;
										}
									})
								}
							</tbody>
						</table>
					}
					{/*begin::Separated tables*/}
					{
						!config.combineConnections &&
						<div className="">
							{/*begin::Recognition table*/}
							{
								config.recognition &&
								<table className="table table-bordered connection-table">
									<thead>
										<tr>
											<th className='table-active fw-bolder' colSpan={4}>Recognition</th>
										</tr>
										<tr>
											<th scope="col" className="w-10">Date</th>
											<th scope="col" className="w-20">Category</th>
											<th scope="col" className="w-70">Comment</th>
										</tr>
									</thead>
									<tbody>
										{
											recognitions.map((recognition, i) => {
												return <tr key={i}>
													<td>{recognition.date}</td>
													<td>{recognition.category?.name}</td>
													<td>{recognition.message}</td>
												</tr>
											})
										}
									</tbody>
								</table>
							}
							{/*end::Recognition table*/}
							{/*begin::Coaching table*/}
							{
								config.coaching &&
								<table className="table table-bordered connection-table">
									<thead>
										<tr>
											<th className='table-active fw-bolder' colSpan={3}>Coaching</th>
										</tr>
										<tr>
											<th scope="col" className="w-10">Date</th>
											<th scope="col" className="w-15">Category</th>
											<th scope="col" className="w-75">Comment</th>
										</tr>
									</thead>
									<tbody>
										{
											coachings.map((coaching, i) => {
												return <tr key={i}>
													<td>{coaching.date}</td>
													<td>{coaching.category?.name}</td>
													<td>{coaching.comment}</td>
												</tr>
											})
										}
									</tbody>
								</table>
							}
							{/*end::Coaching table*/}
							{/*begin::Attendance (unexcused) table*/}
							{
								(config.attendance) &&
								<table className="table table-bordered connection-table">
									<thead>
										<tr>
											<th className='table-active fw-bolder' colSpan={5}>Attendance</th>
										</tr>
										<tr>
											<th scope="col" className="w-10">Date</th>
											<th scope="col" className="w-30">Rule</th>
											<th scope="col" className="w-40">Reason</th>
											<th scope="col" className="w-40">Notes</th>
											<th scope="col" className="w-5">Points</th>
										</tr>
									</thead>
									<tbody>
										{
											attendances.filter(att => !att.reason.isExcused).map((attendance, i) => {
												return (
													<tr key={i}>
														<td>{attendance.date}</td>
														<td>{attendance.occurrence}</td>
														<td>{attendance.reason?.content}</td>
														<td>{attendance.supervisorNote}</td>
														<td>{attendance.pointsAssigned}</td>
													</tr>
												)
											})
										}
									</tbody>
								</table>
							}
							{/*end::Attendance (unexcused) table*/}
							{/*begin::Attendance (excused) table*/}
							{
								(config.excused) &&
								<table className="table table-bordered connection-table">
									<thead>
										<tr>
											<th className='table-active fw-bolder' colSpan={4}>Excused</th>
										</tr>
										<tr>
											<th scope="col" className="w-20">Date</th>
											<th scope="col" className="w-30">Reason</th>
											<th scope="col" className="w-40">Supervisor Note</th>
											<th scope="col" className="w-10">FMLA Hours</th>
										</tr>
									</thead>
									<tbody>
										{
											attendances.filter(att => att.reason.isExcused).map((attendance, i) => {
												return (
													<tr key={i}>
														<td>{attendance.date}</td>
														<td>{attendance.reason?.content}</td>
														<td>{attendance.supervisorNote}</td>
														<td>{attendance.fmla_hours || '-'}</td>
													</tr>
												)
											})
										}
									</tbody>
								</table>
							}
							{/*end::Attendance (excused) table*/}
							{/*begin::Discipline table*/}
							{
								config.discipline &&
								<table className="table table-bordered connection-table">
									<thead>
										<tr>
											<th className='table-active fw-bolder' colSpan={5}>Corrective Action</th>
										</tr>
										<tr>
											<th scope="col" className="w-10">Date</th>
											<th scope="col" className="w-10">Type</th>
											<th scope="col" className="w-20">Step</th>
											<th scope="col" className="w-60">Template</th>
										</tr>
									</thead>
									<tbody>
										{
											disciplines
												.filter(hasDisciplineBeenIssued)
												.map((discipline, i) => {
													const usedTemplate = templatesList.find(tmp => tmp.id === (discipline as unknown as Discipline).templateId);
													// TODO: because this is getting pulled from an old section of the app, it doesn't convert
													//  the textFields JSON like newer areas.
													const incidentDetails = (!(discipline as unknown as Discipline).textFields) ? '' :
														JSON.parse(((discipline as unknown as Discipline).textFields as unknown as string)).incidentDetails;

													return (
														<Fragment key={i}>
															<tr>
																<td>{discipline.date}</td>
																<td>{discipline.step.type}</td>
																<td>{discipline.step.name}</td>
																<td>
																	{!!usedTemplate && (
																		<span>
																			{usedTemplate.type} &gt; {usedTemplate.category} &gt; {usedTemplate.name}
																		</span>
																	)}
																</td>
															</tr>
															{!!usedTemplate && incidentDetails &&
																<tr className="incident-details">
																	<td
																		className="incident-details"
																		colSpan={4}>
																		<span className="fw-bold">Details: </span>{incidentDetails}
																	</td>
																</tr>
															}
														</Fragment>
													)
												})
										}
									</tbody>
								</table>
							}
							{/*end::Discipline table*/}
						</div>
					}
					{/*end::Separated tables*/}
					</div>

					{config.showCalendar && (
					<div className="rolling-calendar-wrapper">
							<div className="calendar-legend col-12 flex-row d-flex flex-wrap justify-content-md-evenly justify-content-sm-start my-5">
								{config.recognition && (
									<div className="event-label recognition-event d-flex justify-content-md-between align-items-center pe-3">
										<span>Recognition</span>
									</div>
								)}
								{config.coaching && (
									<div className="event-label coaching-event d-flex justify-content-md-between align-items-center pe-3">
										<span>Coaching</span>
									</div>
								)}
								{config.attendance && (
									<div className="event-label attendance-event d-flex justify-content-md-between align-items-center pe-3">
										<span>Attendance</span>
									</div>
								)}
								{config.discipline && (
									<div className="event-label discipline-event d-flex justify-content-md-between align-items-center pe-3">
										<span>Corrective Action</span>
									</div>
								)}
								{config.excused && (
									<div className="event-label excused-event d-flex justify-content-md-between align-items-center pe-3">
										<span>Excused</span>
									</div>
								)}
							{/*</div>*/}
						</div>
						{/*<div className="">*/}
							{
								getMonthsForPeriod(config.startDate, config.endDate, 'DESC').map(
									(month, idx) => {
										return (
											<Fragment key={idx}>
												<div className="col-6 p-5 calendar-wrapper d-flex flex-column justify-content-center">
												<h3 className="align-self-center">{month.monthNameShortYear}</h3>
												<Calendar
													key={month.yearMonth}
													className="year-calendar"
													view="month"
													activeStartDate={month.startDate}
													tileDisabled={() => true}
													// onChange={ handleDateClick }
													// value={ selectedDate }
													calendarType="US"
													showNeighboringMonth={false}
													nextLabel={null}
													next2Label={null}
													prevLabel={null}
													prev2Label={null}
													showNavigation={false}
													tileClassName={
														(
															{
																date,
																view,
															}: CalendarTileProperties,
														) => {
															const currDate = moment(date);
															const currDateYMD = currDate.format('YYYY-MM-DD');
															const classNames: string[] = [];
															// don't show events that are outside the range selected, dim them
															if (currDate < startDate || currDate > endDate) {
																return 'outside-active-window';
															}

															// dim dates in the future
															if (moment(date) > todaysDate) {
																classNames.push('outside-active-window');
															}

															combinedConnections
																.map(
																item => parseConnectionsForCalendars(item),
															).map(itm => {
																if (itm.yearMonth === month.yearMonth && itm.date === currDateYMD) {
																	switch ((itm.type as ConnectionType)) {
																		case 'ATTENDANCE':
																			classNames.push('attendance-event');
																			break;
																		case 'EXCUSED':
																			classNames.push('excused-event');
																			break;
																		case 'COACHING':
																			classNames.push('coaching-event');
																			break;
																		case 'RECOGNITION':
																			classNames.push('recognition-event');
																			break;
																		case 'DISCIPLINE':
																			classNames.push('discipline-event');
																			break;
																		default:
																			break;
																	}
																}
															});
															return (classNames.length) ? classNames.join(' ') : 'no-events';
														}
													}
												/>
												</div>
											</Fragment>
										);
									},
								)
							}
						</div>
					)}
					</div>
				<div className="printed_date">{printDate}</div>
			</div>
		</div>
	)
})
