import { useFormik } from 'formik';
import { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { toAbsoluteUrl } from '../../../../../_library/helpers';
import { SkeButtonLoading } from '../../../../../common/components/ButtonLoading';
import { RootState } from '../../../../../setup';
import { PhoneNumberInput } from '../../../../components/PhoneNumberInput';
import { UserModel } from '../../../../models';
import { setUserAvatar, updateUsername, updateUsernameAndPhone } from '../../../../modules/auth/redux/AuthCRUD';
import * as auth from '../../../../modules/auth/redux/AuthRedux';

const userSettingsSchema = Yup.object().shape({
	firstName: Yup.string().required('First name is required'),
	lastName: Yup.string().required('Last name is required'),
});

export const UserSettings = () => {
	const dispatch = useDispatch();
	const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;
	const token: string = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual) as string;
	const [avatar, setAvatar] = useState<any>('');
	const [avatarFile, setAvatarFile] = useState<any>();

	const initialValues = {
		firstName: user.firstName,
		lastName: user.lastName,
		phone: user.phone ? user.phone : '',
		phoneChecked: false,
	};

	const [isLoading, setIsLoading] = useState(false);
	const formik = useFormik({
		initialValues,
		validationSchema: userSettingsSchema,
		onSubmit: async (values) => {
			if (user.company) {
				setIsLoading(true);
				try {
					if (!values.phone) {
						await updateUsername(values.firstName, values.lastName, token);
					} else {
						await updateUsernameAndPhone(values.firstName, values.lastName, values.phone, token);
					}

					dispatch(auth.actions.setUser(
						{
							...user,
							firstName: values.firstName,
							lastName: values.lastName,
							phone: values.phone,
						},
					));

					if (avatarFile) {
						const requestBody = new FormData();
						requestBody.append('file', avatarFile);
						await setUserAvatar(user.id, requestBody, token);
						setIsLoading(false);
						window.location.reload();
					} else {
						setIsLoading(false);
					}
				} catch (error: any) {
					console.log(error);
					setIsLoading(false);
					toast.error(error.response.data.message, {
						position: 'top-right',
						theme: 'colored',
						autoClose: 3500,
					});
				}
			}
		},
	});

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files[0]) {
			let reader = new FileReader();
			let file = e.target.files[0];
			setAvatarFile(file);
			reader.onloadend = () => {
				if (reader && reader.result) {
					setAvatar(reader.result);
				}
			};
			reader.readAsDataURL(file);
		}
	};

	const removeAvatar = () => {
		setAvatar('');
		setAvatarFile(null);
	};

	return (
		<div className="card mb-5 mb-xl-10">
			<div
				className="card-header border-0 cursor-pointer"
				role="button"
				data-bs-toggle="collapse"
				data-bs-target="#kt_account_profile_details"
				aria-expanded="true"
				aria-controls="kt_account_profile_details"
			>
				<div className="card-title m-0">
					<h3 className="fw-bolder m-0">User Profile Details</h3>
				</div>
			</div>

			<div
				id="kt_account_profile_details"
				className="collapse show">
				<form
					onSubmit={formik.handleSubmit}
					noValidate
					className="form">
					<div className="card-body border-top p-9">
						<div className="row mb-6">
							<label className="col-lg-4 col-form-label fw-bold fs-6">Avatar</label>
							<div className="col-lg-8">
								<div className="d-flex align-items-center justify-content-center rounded bg-white bg-body shadow w-150px h-150px">
									<div
										className={`image-input image-input-outline w-120px h-120px ${avatar === '' ? 'image-input-empty' : ''}`}
										data-kt-image-input="true"
										style={{backgroundImage: `url(${(!user.avatarUrl || user.avatarUrl?.includes('media/icons/duotune/general/create-team-icon')) ? toAbsoluteUrl('/media/avatars/blank.png') : user.avatarUrl})`}}
									>
										<div
											className="image-input-wrapper no-shadow shadow-none"
											style={{backgroundImage: `url(${toAbsoluteUrl(avatar)})`}}
										></div>
										<label
											className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
											data-kt-image-input-action="change"
											data-bs-toggle="tooltip"
											title=""
											data-bs-original-title="Change avatar">
											<i className="bi bi-pencil-fill fs-7"></i>
											<input
												type="file"
												name="avatar"
												accept=".png, .jpg, .jpeg"
												onChange={handleFileChange} />
											<input
												type="hidden"
												name="avatar_remove" />
										</label>
										<span
											className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
											data-kt-image-input-action="remove"
											data-bs-toggle="tooltip"
											title=""
											data-bs-original-title="Remove avatar"
											onClick={removeAvatar}>
                      <i className="bi bi-x fs-2"></i>
                    </span>
									</div>
								</div>
								<div className="form-text">Set the product thumbnail image. Only
																					 *.png, *.jpg and *.jpeg image files
																					 are accepted
								</div>
							</div>
						</div>

						<div className="row mb-6">
							<label className="col-lg-4 col-form-label required fw-bold fs-6">Full Name</label>
							<div className="col-lg-8">
								<div className="row">
									<div className="col-lg-6 fv-row">
										<input
											type="text"
											className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
											placeholder="First name"
											{...formik.getFieldProps('firstName')}
										/>
										{formik.touched.firstName && formik.errors.firstName && (
											<div className="fv-plugins-message-container">
												<div className="fv-help-block">{formik.errors.firstName}</div>
											</div>
										)}
									</div>

									<div className="col-lg-6 fv-row">
										<input
											type="text"
											className="form-control form-control-lg form-control-solid"
											placeholder="Last name"
											{...formik.getFieldProps('lastName')}
										/>
										{formik.touched.lastName && formik.errors.lastName && (
											<div className="fv-plugins-message-container">
												<div className="fv-help-block">{formik.errors.lastName}</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-6">
							<label className="col-lg-4 col-form-label fw-bold fs-6">
								<span className="required">Contact Phone</span>
							</label>

							<div className="col-lg-8 fv-row">
								<PhoneNumberInput
									label=""
									phoneNumber={formik.values.phone}
									formik={formik}
									setPhoneNumber={phone => {
										formik.setFieldValue('phone', phone, true);
									}}
									error={(formik.touched.phone && formik.errors.phone) ? formik.errors.phone : ''} />
							</div>
						</div>
					</div>

					<div className="card-footer d-flex justify-content-end py-6 px-9">
						<SkeButtonLoading
							isLoading={isLoading}
							loadingMsg={'Please Wait...'}
							type={'submit'}
						>
							Save Changes
						</SkeButtonLoading>
					</div>
				</form>
			</div>
		</div>
	);
};
