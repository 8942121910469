import {DisciplineStep} from '../../../app/modules/discipline/models/DisciplineStep.models'
import { Employee } from '../../../app/modules/employee/models/Employee.model';
import {Team} from '../../../app/modules/team/models/Team.model'
import {User} from '../../../app/modules/user/models/User.model'
import {Company} from '../../../app/modules/company/models/Company.model'
import {ConnectionTypeEnum} from '../../../app/types/ConnectionType'
import {TemplateModel} from '../../../app/modules/performance/models/PerformanceCategory.models'
import {CategoryModel} from '../../category/interfaces/category.model'
import {Attendance} from '../../attendance/interfaces/attendance.model'
import {ApiRequestPaginationOptionsModel} from '../../../app/modules/shared/models/ApiRequest.model'

export enum DisciplineStatusEnum {
	'Draft' = 'Draft',
	'RequestedChange' = 'RequestedChange',
	'RequestedApproval' = 'RequestedApproval',
	'DisciplineDeclined' = 'DisciplineDeclined',
	'Approved' = 'Approved',
	'IssuedToEmployee' = 'IssuedToEmployee',
	'SubmittedToHr' = 'SubmittedToHr',
	'ReceivedByHr' = 'ReceivedByHr',
	'DocumentationFiled' = 'DocumentationFiled',
}


export enum DisciplineStatusForHumansEnum {
	'Draft' = 'Draft',
	'RequestedChange' = 'Change requested by approver',
	'RequestedApproval' = 'Requested approval',
	'DisciplineDeclined' = 'Discipline declined by approver',
	'Approved' = 'Approved, ready to issue to employee',
	'IssuedToEmployee' = 'Issued to employee',
	'SubmittedToHr' = 'Submitted to HR',
	'ReceivedByHr' = 'Received by HR',
	'DocumentationFiled' = 'Documentation filed',
}

export type DisciplineStatusType =
	DisciplineStatusEnum.Draft |
	DisciplineStatusEnum.RequestedChange |
	DisciplineStatusEnum.RequestedApproval |
	DisciplineStatusEnum.DisciplineDeclined |
	DisciplineStatusEnum.Approved |
	DisciplineStatusEnum.IssuedToEmployee |
	DisciplineStatusEnum.SubmittedToHr |
	DisciplineStatusEnum.ReceivedByHr |
	DisciplineStatusEnum.DocumentationFiled;

export enum DisciplineActions {
	Delete = 'DELETE',
	Print = 'PRINT',
	Save = 'SAVE',
	NoChange = 'NO_CHANGE',
	Draft = 'DRAFT',
	Decline = 'DECLINE',
	RequestChanges = 'REQUEST_CHANGES',
	Approve = 'APPROVE',
	RequestApproval = 'REQUEST_APPROVAL',
	Issue = 'ISSUE',
	Submit = 'SUBMIT',
	Receive = 'RECEIVE',
	FastFile = 'FAST_FILE',
}

export type DisciplineStatusActions = Exclude<DisciplineActions, DisciplineActions.Delete |  DisciplineActions.Print |  DisciplineActions.Save |  DisciplineActions.NoChange>

export interface Discipline {
	id: number;
	tenant_id: string;
	created_at: string;
	updated_at: string;
	status: DisciplineStatusType;
	supervisorNote: string;
	isSigned: boolean;
	closed_at: string;
	overwritten_at: string;
	stepId?: number;
	step: DisciplineStep;
	employeeId?: number;
	employee: Employee;
	teamId: number;
	team: Team;
	creatorId: number;
	creator: User;
	reviewerId: number;
	reviewer: User;
	company: Company;
	connectionType: ConnectionTypeEnum.Discipline;
	date: string;
	templateId: number;
	// only returned on the findOne discipline call
	template?: TemplateModel;
	categoryId: number;
	category?: CategoryModel;
	infractions: (Attendance | Discipline)[];
	textFields: DisciplineTextFields;
	changes: string;
	category_id: number;
	metadata?: any;
	misc?: DisciplineMiscField;
}

export interface DisciplineTextFields {
	incidentDetails: string;
	expectations: string;
	consequences: string;
}

export interface DisciplineMiscField {
	comments?: string;
	employeeRefusedToSign?: boolean;
}

export type DisciplineTextFieldType = 'incidentDetails' | 'expectations' | 'consequences';

export interface DisciplineCreate {
	date: string | undefined;
	stepId: number;
	employeeId: number;
	teamId: number;
	reviewerId?: number;
	supervisorNote?: string;
	templateId: number;
	infractions: string;
	textFields: string;
	category_id: number;
	metadata?: any;
	creatorId?: number;
	status?: DisciplineStatusType;
	// for RequestedChanges only
	changes?: string;
	isSigned: boolean;
	misc?: DisciplineMiscField;
}

export interface DisciplineUpdate extends Omit<DisciplineCreate, 'employeeId' | 'teamId'>, Pick<Discipline, 'id'> {}

export interface DisciplinesGetAllApiRequestModel extends ApiRequestPaginationOptionsModel {
	search?: string;
	fromDate?: string;
	toDate?: string;
	sort?: 'date' | '-date' | 'firstName' | '-firstName';
	employeeId?: number;
	teamId?: number;
	creatorId?: number;
}
