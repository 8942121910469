import { ReactNode, useState, useRef } from "react";
import {Overlay, Popover} from "react-bootstrap";
import clsx from "clsx";

type TriggerType = "hover" | "click" | "focus";

interface SkePopoverProps {
	placement:
		| "auto-start"
		| "auto"
		| "auto-end"
		| "top-start"
		| "top"
		| "top-end"
		| "right-start"
		| "right"
		| "right-end"
		| "bottom-end"
		| "bottom"
		| "bottom-start"
		| "left-end"
		| "left"
		| "left-start";
	triggerElement: ReactNode;
	trigger: TriggerType[];
	className?: string;
	children: ReactNode;
	closeOnContentClick?: boolean;
}

export function SkePopover({
  placement = "top",
  triggerElement,
  trigger,
  className = "",
  children,
  closeOnContentClick = false
}: SkePopoverProps) {
	const [show, setShow] = useState(false);

	const triggerRef = useRef<HTMLDivElement>(null);

	const isTriggerEnabled = (type: TriggerType) => trigger.includes(type);

	const handleMouseEnter = () => {
		if (isTriggerEnabled("hover")) {
			setShow(true);
		}
	};

	const handleMouseLeave = () => {
		if (isTriggerEnabled("hover")) {
			setShow(false);
		}
	};

	const handleClick = () => {
		if (isTriggerEnabled("click")) {
			setShow((prevShow) => !prevShow);
		}
	};

	const handleFocus = () => {
		if (isTriggerEnabled("focus")) {
			setShow(true);
		}
	};

	const handleBlur = () => {
		if (isTriggerEnabled("focus")) {
			setShow(false);
		}
	};

	return (
		<>
			<div
				ref={triggerRef}
				onClick={handleClick}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onFocus={handleFocus}
				onBlur={handleBlur}
				className='d-inline-block cursor-pointer w-100'
			>
				{triggerElement}
			</div>
			<Overlay
        rootClose
        onHide={() => setShow(false)}
        target={triggerRef.current}
				show={show}
				placement={placement}
			>
        <Popover
						id="interactive-popover-tooltip"
						className={clsx('p-4', className)}
						onClick={(e) => {
							if (closeOnContentClick) {
								setShow(false)
							}
						}}
					>
						{children}
					</Popover>
      </Overlay>
		</>
	)
}
