import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const DISCIPLINES_URL = `${API_URL}/disciplines`


export function getOneDiscipline(disciplineId: number, token: string) {
  return axios({
    method: 'get',
    url: `${DISCIPLINES_URL}/${disciplineId}`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}


export function removeDiscipline(disciplineId: number, token: string) {
  return axios({
    method: 'delete',
    url: `${DISCIPLINES_URL}/${disciplineId}`,
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  })
}
