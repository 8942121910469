import React from 'react';
import { getTodaysTimeOrOlderDate } from '../../modules/date/utils/DateHelpers';
import { NotificationType } from '../../types/NotificationType'
import moment from 'moment';
import './NotificationItem.scss'
import { toAbsoluteUrl } from '../../../_library/helpers'
import { DeleteIcon } from '../../../app/components/icons/DeleteIcon';
import { Icon } from '../icons/Icon';
import { Tools } from '../icons/IconList';

interface Props {
    notification: NotificationType
    deleteNotification: () => void;
}


export function NotificationItemMaintenance({
                              notification,
                              deleteNotification}: Props) {

    return (
        <div
            style={{ background: notification.isRead ? 'transparent' : '#FAFBFC' }}
            className='notification_wrapper d-flex align-items-center'
            title='Scheduled Maintenance'
            data-bs-toggle="tooltip"
            data-bs-placement="left"
        >
            <div>
            <button
                className="btn btn-sm d-inline p-0 bg-hover-light-danger text-hover-danger"
                onClick={(e) => {
                    e.stopPropagation();
                    deleteNotification();
                }}
                >
                <DeleteIcon />
                </button>
            </div>
            <Icon
                icon={Tools}
                classes='ms-4 text-muted'
                size='lg'
                use='decorative'
                type='svg'
                />
            <div className="notification_content d-flex flex-column mx-5 align-top">
                <div className="notification_creator_name align-top">
                    {/* @ts-ignore*/}
                    {notification.metaData?.target_date}
                </div>
                <div className="notification_message">
                    {/* @ts-ignore*/}
                    {notification.metaData.window_start} - {notification.metaData.window_end} Central Time
                </div>
            </div>
            <div className="notification_action d-flex flex-column align-items-end ms-auto">
                <div className="notification_date">
                    {getTodaysTimeOrOlderDate(moment(notification.created_at).toDate())}
                </div>
                <span
                    style={{ background: notification.isRead ? 'transparent' : '#EB2525' }}
                    className="notification_mark"
                ></span>
            </div>
        </div>

    )
}
