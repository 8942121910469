import clsx from 'clsx';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { AvatarSize } from '../../../../app/components/Avatar';
import { DatePicker } from '../../../../app/components/DatePicker';
import { EmployeeBadge } from '../../../../app/components/EmployeeBadge';
import { createCoaching, updateCoaching } from '../../../../app/CRUD/CoachingCRUD';
import { DateFormatUsingDateFns } from '../../../../app/modules/date/DateFormat.const';
import { parseDateObjectToNewFormat, parseYmdDatesToDateObject } from '../../../../app/modules/date/utils/DateHelpers';
import { Employee } from '../../../../app/modules/employee/models/Employee.model';
import { CoachingType } from '../../../../app/types/CoachingType';
import { EmployeeType } from '../../../../app/types/EmployeeType';
import { SkePopover } from '../../../../common/components/Popover';
import { SkeModal } from '../../../../common/modals/generic/SkeModal';
import infoIcon from '../../../../images/icons/info-circle.svg';
import submitIcon from '../../../../images/icons/white_floppy.svg';
import { RootState } from '../../../../setup';
import { coachingCategorySlice } from '../../coaching-categories.api';
import { coachingModalSlice } from '../../coaching-modal.slice';
import { Coaching } from '../../interfaces/coaching.model';

interface CoachingDetails {
	category: string;
	comments?: string;
}

const coachingSchema = Yup.object().shape({});

interface Props {
	show: boolean;
	employee: Employee | EmployeeType | undefined;
	title: string;
	type: 'new' | 'edit' | 'view';
	coaching?: Coaching;
	refresh?: (updated: CoachingType) => void;
}

export function CoachingModal({
																show,
																employee,
																title,
																type,
																coaching,
																refresh,
															}: Props) {
	const token: string = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual) as string;
	const [coachingDate, setCoachingDate] = useState<Date>(new Date());
	const [loading, setLoading] = useState(false);
	const {data: categories} = coachingCategorySlice.useGetCoachingCategoriesQuery({});
	const [initValues, setInitValues] = useState<CoachingDetails>({
		category: coaching?.category?.id + '',
		comments: coaching?.comment,
	});
	const dispatch = useDispatch();

	const formik = useFormik<CoachingDetails>({
		enableReinitialize: true,
		initialValues: initValues,
		validationSchema: coachingSchema,
		onSubmit: (values) => {
			if (!coachingDate) {
				toast.error(`Please select a valid date`);
				throw new Error(`Invalid date found: ${coachingDate}`);
			}
			if (type === 'edit') {
				if (coaching) {
					updateCoaching(
						coaching?.id,
						{
							coachingCategoryId: parseInt(values.category),
							date: parseDateObjectToNewFormat(coachingDate, DateFormatUsingDateFns.PerfectDate),
							teamId: coaching.team?.id,
							comment: values.comments,
						},
						token,
					)
						.then((res) => {
							dispatch(coachingModalSlice.actions.hide(true));
							if (refresh) {
								refresh(res.data);
							}
						})
						.catch((err) => console.error(err));
				}
			}
			if (type === 'new') {
				if (employee) {
					if (employee.teams) {
						setLoading(true);
						createCoaching(
							{
								coachingCategoryId: parseInt(values.category),
								date: parseDateObjectToNewFormat(coachingDate, DateFormatUsingDateFns.PerfectDate),
								employeeId: employee?.id,
								teamId: employee?.teams[0]?.id,
								comment: values.comments,
							},
							token,
						)
							.then((res) => {
								setLoading(false);
								dispatch(coachingModalSlice.actions.hide(true));
								if (refresh) {
									refresh(res.data);
								}
							})
							.catch((err) => console.log(err));
					}
				}
			}
		},
	});

	const handleCoachingDate = (date: Date | null) => {
		if (!!date) {
			if (date < new Date()) {
				setCoachingDate(date);
			} else {
				toast.error('Please select a correct date', {
					position: 'top-right',
					theme: 'colored',
					autoClose: 3500,
				});
			}
		}
	};

	useEffect(() => {
		let abortController = new AbortController();

		setInitValues({
			category: coaching?.category?.id + '',
			comments: coaching?.comment,
		});

		if (coaching?.date) {
			setCoachingDate(parseYmdDatesToDateObject(coaching.date));
		}
		return () => {
			abortController.abort();
		};
	}, [
		coaching,
		token,
	]);

	const commentingTipsPopoverElement = <SkePopover
		trigger={['click']}
		placement="auto"
		triggerElement={
			<button
				className="btn p-0 ms-1"
				type="button"
				aria-label="commenting tips">
				<img
					className="w-12px mb-1"
					src={infoIcon}
					alt="Icon" />
			</button>
		}
	>
		<div className="p-3">
			<h4 className="text-start text-muted">Pro Tips</h4>
			<ul className="text-start list-group list-group-flush">
				<li className="list-group-item px-0 text-muted">
					Trust: Ask questions! Inquisitive state of mind to understand their actions.
					This is an opportunity to build trust and your working relationship
				</li>
				<li className="list-group-item px-0 text-muted">
					Focus the conversation on the topic the individual or you would like to see
					improved
				</li>
				<li className="list-group-item px-0 text-muted">
					Action: What is the plan for action, when will you follow up, and what have they
					committed to do?
				</li>
				<li className="list-group-item px-0 text-muted">
					Follow up: When and how will you follow up? What does the future look and feel
					like when this goes well
				</li>
			</ul>
		</div>
	</SkePopover>;

	return (
		<SkeModal
			title={title}
			show={show}
			onClose={() => dispatch(coachingModalSlice.actions.hide(true))}
			customHeaderContents={
				<>
					<h1 className="mb-3">{title}</h1>
					<EmployeeBadge
						employee={employee}
						size={AvatarSize.lg} />
				</>
			}
			showFooter={false}
		>
			{type === 'view' && (
				<div className="modal-body mx-0 p-0 pt-3">
					<div className="d-flex flex-wrap flex-grow-1">
						<div className="row border-bottom border-bottom-1">
							<div className="col-5 col-sm-4 mx-2 pb-3">
								<span className="fw-bolder d-block">Date</span>
								<span className="d-block">{coaching?.date}</span>
							</div>
							<div className="col-5 col-sm-4 mx-2 pb-3">
								<span className="fw-bolder d-block">Category</span>
								<span className="d-block">{coaching?.category?.name}</span>
							</div>
							<div className="col-5 col-sm-4 mx-2 pb-3">
								<span className="fw-bolder d-block">Coached By</span>
								<span className="d-block">
									{coaching?.coacher?.firstName} {coaching?.coacher?.lastName}
								</span>
							</div>
						</div>

						<div className="row mx-2 pt-3">
							<span className="fs-2 fw-bolder d-block">Comments</span>
							<p className={clsx({'fst-italic': !coaching?.comment})}>
								{coaching?.comment || 'No details provided'}
							</p>
						</div>
					</div>
				</div>
			)}
			{type === 'edit' && (
				<form
					onSubmit={formik.handleSubmit}
					noValidate
					className="connection_form">
					<div className="card-body p-0">
						<div>
							<div>
								<div className="mb-4">
									<label className="col-form-label form_label pt-0 pb-1">Event Date</label>
									<DatePicker
										labelClasses="col-form-label fw-light fs-4"
										id="coaching-add-date"
										selectedDate={coachingDate}
										onChange={(d) => handleCoachingDate(d)}
										dateFormat="MMMM d, yyyy"
									/>
								</div>
								<div className="mb-4">
									<label className="col-form-label form_label pb-1">Category</label>
									<select
										className="form-select form-select-solid form-select-lg form_control"
										{...formik.getFieldProps('category')}
									>
										<option
											className="pe-3"
											value="">Select one
										</option>
										{categories?.length &&
											categories.map((category, i) => (
												<option
													className="pe-3"
													key={category.id}
													value={category.id}>
													{category.name}
												</option>
											))}
									</select>
									{formik.touched.category && formik.errors.category && (
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">{formik.errors.category}</div>
										</div>
									)}
								</div>
							</div>
						</div>

						<div className="row form_row mb-0">
							<div>
								<label className="d-inline-flex align-items-center gap-2 col-form-label form_label pb-1">Comments</label>
								{commentingTipsPopoverElement}
								<textarea
									rows={4}
									className="form_control form-control-lg form-control-solid w-100"
									{...formik.getFieldProps('comments')}
								></textarea>
								{formik.touched.comments && formik.errors.comments && (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">{formik.errors.comments}</div>
									</div>
								)}
							</div>
						</div>
					</div>

					<div className="mt-5 card-footer d-flex justify-content-end border-0 p-0">
						<button
							type="submit"
							className="btn btn-primary d-flex align-items-center gap-1"
							disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
						>
							<i className="bi bi-pencil-fill fs-7"></i>
							Update
							{formik.isSubmitting && (
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							)}
						</button>
					</div>
				</form>
			)}
			{type === 'new' && (
				<form
					onSubmit={formik.handleSubmit}
					noValidate
					className="connection_form">
					<div className="card-body p-0">
						<div>
							<div>
								<div className="mb-4">
									<label className="col-form-label form_label pt-0 pb-1">Event Date</label>
									<DatePicker
										inputClasses="w-100"
										labelClasses="col-form-label fw-light fs-4"
										id="recognition-add-date"
										selectedDate={coachingDate}
										onChange={(d) => handleCoachingDate(d)}
										dateFormat="MMMM d, yyyy"
									/>
								</div>
								<div className="mb-4">
									<label className="col-form-label form_label pb-1">Category</label>
									<select
										className="form-select form-select-solid form-select-lg form_control"
										{...formik.getFieldProps('category')}
									>
										<option
											className="pe-3"
											value="">Select one
										</option>
										{categories?.length &&
											categories.map((category, i) => (
												<option
													className="pe-3"
													key={category.id}
													value={category.id}>
													{category.name}
												</option>
											))}
									</select>
									{formik.touched.category && formik.errors.category && (
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">{formik.errors.category}</div>
										</div>
									)}
								</div>
							</div>
						</div>

						<div className="row form_row mb-0">
							<div>
								<label className="d-inline-flex align-items-center gap-2 col-form-label form_label pb-1">Comments</label>
								{commentingTipsPopoverElement}
								<textarea
									rows={4}
									className="form_control form-control-lg form-control-solid w-100"
									{...formik.getFieldProps('comments')}
								></textarea>
								{formik.touched.comments && formik.errors.comments && (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">{formik.errors.comments}</div>
									</div>
								)}
							</div>
						</div>
					</div>

					<div className="mt-5 card-footer d-flex justify-content-end border-0 p-0">
						<button
							type="submit"
							className="btn btn-primary d-flex align-items-center gap-1"
							disabled={loading || formik.values.category === 'undefined'}
						>
							{!loading && <img
								className="me-1"
								src={submitIcon}
								width="12"
								alt="submit" />}
							{!loading && 'Save'}
							{loading && (
								<span
									className="indicator-progress"
									style={{display: 'block'}}>
									Please wait...{' '}
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
								</span>
							)}
						</button>
					</div>
				</form>
			)}
		</SkeModal>
	);
}
